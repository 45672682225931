.main-menu {
	$menu: &;
	z-index: 240;
	position: fixed;
	display: block;
	background-color: #fff;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding-top: 120px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	transform: translateX(-100%);
	transition: all 0.2s ease 500ms;

	@media (min-width: 1024px) {
		display: none;
		visibility: hidden;
	}

	&.open {
		transform: translateX(0);
		transition: all 0.2s ease 0ms;
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex: 1;
		min-height: 336px;
		max-width: 300px;
		width: 100%;
		margin: 0 auto;
	}

	&__item {
		text-align: center;
	}

	&__link {
		font-size: 20px;
		color: red;
		font-weight: 500;
		text-decoration: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: 1px solid #d2d2d2;
		padding: 15px 0;
		font-family: moret, serif;

		&:hover {
			color: #3c3c3c;
		}
	}
}
