.about {
	padding-top: 40px;
	padding-bottom: 40px;

	&__logo {
		margin-bottom: 20px;
	}

	&__intro {
		font-size: 2rem;
		line-height: 2rem;
	}

	&__icon {
		width: 25px;
		margin: 20px 0;
	}

	h3 {
		margin-bottom: 20px;
	}
	p {
		margin-bottom: 20px;
	}
}
