.insta-feed {
	padding-top: 40px;
	padding-bottom: 40px;

	h2 {
		font-size: 2rem;
		line-height: 2rem;
		margin-bottom: 15px;

		@media (min-width: 1024px) {
			font-size: 4rem;
			line-height: 4rem;
		}
	}
	p {
		max-width: 520px;
		margin: 0 auto;
		font-size: 1.2rem;
		line-height: 1.3rem;
		font-family: moret, serif;
		margin-bottom: 15px;

		@media (min-width: 1024px) {
			font-size: 2rem;
			line-height: 2.1rem;
		}
	}

	p.follow {
		font-size: 1rem;
	}

	#curator-feed-default-feed-layout {
		margin-top: 20px;
	}
}
