$purple: #fdd0e0;
$red: #ff0000;

@import "./utilities/reset.scss";
@import "./base.scss";
@import "./header";
@import "./footer.scss";
@import "./desktop-menu";
@import "./mobile-menu";

@import "./hero.scss";
@import "./about.scss";
@import "./band.scss";
@import "./insta-feed.scss";
@import "./video.scss";

.pattern {
	position: relative;
	background: url("../assets/pattern.png");
	height: 150px;
	width: 100vw;
	background-repeat: no-repeat;
	background-size: auto;

	@media (min-width: 1024px) {
		background-attachment: fixed;
		background-size: cover;
	}
}
