.header {
	$header: &;
	width: 100%;
	position: fixed;
	z-index: 250;
	background-color: rgba(#fff, 0.4);

	&.collapsed {
		background-color: rgba(#fff, 1);
	}

	&__wrapper {
		position: relative;
		max-width: 1024px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 21px 25px;
		margin: 0 auto;
		z-index: 260;

		@media (min-width: 1024px) {
			padding: 20px 0;
		}
	}

	&__logo {
		width: 96px;
		height: 48px;
		z-index: 1000;
		transition: color 200ms ease 600ms;
		color: #000;
		z-index: 270;

		@media (min-width: 1024px) {
			width: 135px;
			height: 60px;
		}

		a {
			color: inherit;
			display: block;

			svg {
				width: 77px;
				height: 33px;
				@media (min-width: 1024px) {
					width: 135px;
					height: 60px;
				}
			}
		}

		&.open {
			color: #fff;
			transition: color 200ms ease 0ms;
		}
	}

	&__menu-toggle {
		$menu: &;
		z-index: 1000;
		padding: 5px;
		cursor: pointer;
		width: 20px;
		height: 18px;
		position: relative;

		@media (min-width: 1024px) {
			display: none;
		}

		&__line {
			position: absolute;
			width: 100%;
			height: 2px;
			margin-bottom: 6px;
			top: 0;
			left: 0;

			&.one {
				background-color: red;
				transform: rotate(0) translate(0, 0);
				transition: transform 200ms ease,
					background-color 100ms ease 500ms;
			}

			&.two {
				background-color: red;
				top: 8px;
				transform: rotate(0) translate(0, 0);
				transition: transform 200ms ease,
					background-color 100ms ease 500ms;
			}

			&.three {
				background-color: red;
				top: 16px;

				right: 0;
				left: unset;
				transform: rotate(0) translate(0, 0);
				transition: width 150ms ease 140ms,
					background-color 100ms ease 500ms;
			}
		}

		&.open {
			#{$menu}__line {
				background-color: red;
				&.one {
					transform: rotate(42deg) translate(5px, 6px);
					transition: transform 200ms ease 200ms,
						background-color 100ms ease 120ms;
				}
				&.two {
					transform: rotate(-43deg) translate(0, 0);
					transition: transform 200ms ease 200ms,
						background-color 100ms ease 120ms;
				}
				&.three {
					width: 0;
					transition: width 100ms ease 145ms,
						background-color 100ms ease 120ms;
				}
			}
		}
	}
}

.social-list {
	&__list {
		display: flex;
	}

	&__item {
		a {
			margin-left: 20px;
			&:hover {
				svg {
					color: black;
				}
			}
		}

		&:first-child {
			a {
				margin-left: 0;
			}
		}

		svg {
			width: 30px;
			color: red;
		}
	}

	@media (min-width: 1024px) {
		padding: 20px 0;

		img {
			width: 40px;
		}
	}
}
