.video {
	display: grid;
	padding: 40px 20px;
	max-width: 1400px;
	margin: 0 auto;
    grid-template-columns: 1fr 1fr;  
    gap: 20px;

	@media (max-width: 768px) {
		flex-direction: column;
        grid-template-columns: 1fr;
	}

	&__wrapper {
		position: relative;
		width: 100%;
		height: 0;
        padding-bottom: 52.25%;
	}

	&__iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
