.band {
	max-width: 1200px;
	margin: 0 auto;
	position: relative;

	&__image {
		width: 100%;
		display: block;
	}
	&__logo {
		width: 300px;
		margin: 20px auto 0;
		display: block;
	}

	.row {
		position: absolute;
		display: flex;
		max-width: 950px;
		margin: 0 auto;
		width: 100%;
		z-index: 2;
		top: 40px;
		left: 50%;
		transform: translateX(-50%);

		@media (max-width: 1024px) {
			position: relative;
			top: 10px;
			padding-bottom: 20px;
		}
	}

	.col {
		flex: 0 0 25%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		text-align: center;

		h3 {
			font-size: 35px;
			margin-bottom: 15px;
		}
		h5 {
			font-size: 18px;
			margin-bottom: 5px;
		}
		h6 {
			font-size: 16px;
		}

		br {
			display: none;
		}

		@media (max-width: 1024px) {
			h3 {
				font-size: 27px;
				margin-bottom: 5px;
			}
			h5 {
				font-size: 16px;
				margin-bottom: 10px;
			}
			h6 {
				font-size: 13px;
			}
			br {
				display: block;
			}
		}
	}
}
 