.hero {
	width: 100%;
	padding-top: 90px;

	img {
		height: 100%;
		width: 100%;
	}
	@media (min-width: 1024px) {
		padding-top: 120px;
	}
}
