html,
body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	background-color: $purple;
	color: $red;
	font-weight: 700;
	font-style: normal;
	font-family: Raleway, serif;
	font-size: 18px;
	line-height: 26px;
    scroll-behavior: smooth;

	@media (min-width: 1042px) {
		font-size: 16px;
		line-height: 24px;
	}
}

h1,
h2,
h3 {
	font-family: moret, serif;
	font-weight: 700;
	font-style: normal;
	color: red;
}

.content-s {
	padding: 0 20px;
	text-align: center;
	max-width: 540px;
	margin: 0 auto;
}

.content-l {
	padding: 0 20px;
	text-align: center;
	max-width: 1160px;
	margin: 0 auto;
}
