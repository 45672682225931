*, :before, :after {
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

html, body {
  min-height: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

img, iframe, video, audio, object {
  max-width: 100%;
}

img, iframe {
  border: 0;
}

img {
  height: auto;
  vertical-align: middle;
  display: inline-block;
}

b, strong {
  font-weight: bold;
}

address {
  font-style: normal;
}

svg:not(:root) {
  overflow: hidden;
}

a, button, input[type="submit"], input[type="button"], input[type="reset"], input[type="file"], input[type="image"], label[for] {
  cursor: pointer;
}

a[href^="tel"], button[disabled], input[disabled], textarea[disabled], select[disabled] {
  cursor: default;
}

input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea, a[href^="tel"] {
  appearance: none;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  resize: none;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

button, select {
  text-transform: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

nav ul, nav ol {
  list-style: none;
}

html, body {
  box-sizing: border-box;
  color: red;
  scroll-behavior: smooth;
  background-color: #fdd0e0;
  margin: 0;
  padding: 0;
  font-family: Raleway, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

@media (min-width: 1042px) {
  html, body {
    font-size: 16px;
    line-height: 24px;
  }
}

h1, h2, h3 {
  color: red;
  font-family: moret, serif;
  font-style: normal;
  font-weight: 700;
}

.content-s {
  text-align: center;
  max-width: 540px;
  margin: 0 auto;
  padding: 0 20px;
}

.content-l {
  text-align: center;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  width: 100%;
  z-index: 250;
  background-color: #fff6;
  position: fixed;
}

.header.collapsed {
  background-color: #fff;
}

.header__wrapper {
  max-width: 1024px;
  width: 100%;
  z-index: 260;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 21px 25px;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .header__wrapper {
    padding: 20px 0;
  }
}

.header__logo {
  width: 96px;
  height: 48px;
  z-index: 1000;
  color: #000;
  z-index: 270;
  transition: color .2s .6s;
}

@media (min-width: 1024px) {
  .header__logo {
    width: 135px;
    height: 60px;
  }
}

.header__logo a {
  color: inherit;
  display: block;
}

.header__logo a svg {
  width: 77px;
  height: 33px;
}

@media (min-width: 1024px) {
  .header__logo a svg {
    width: 135px;
    height: 60px;
  }
}

.header__logo.open {
  color: #fff;
  transition: color .2s;
}

.header__menu-toggle {
  z-index: 1000;
  cursor: pointer;
  width: 20px;
  height: 18px;
  padding: 5px;
  position: relative;
}

@media (min-width: 1024px) {
  .header__menu-toggle {
    display: none;
  }
}

.header__menu-toggle__line {
  width: 100%;
  height: 2px;
  margin-bottom: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

.header__menu-toggle__line.one {
  background-color: red;
  transition: transform .2s, background-color .1s .5s;
  transform: rotate(0)translate(0);
}

.header__menu-toggle__line.two {
  background-color: red;
  transition: transform .2s, background-color .1s .5s;
  top: 8px;
  transform: rotate(0)translate(0);
}

.header__menu-toggle__line.three {
  top: 16px;
  right: 0;
  left: unset;
  background-color: red;
  transition: width .15s .14s, background-color .1s .5s;
  transform: rotate(0)translate(0);
}

.header__menu-toggle.open .header__menu-toggle__line {
  background-color: red;
}

.header__menu-toggle.open .header__menu-toggle__line.one {
  transition: transform .2s .2s, background-color .1s .12s;
  transform: rotate(42deg)translate(5px, 6px);
}

.header__menu-toggle.open .header__menu-toggle__line.two {
  transition: transform .2s .2s, background-color .1s .12s;
  transform: rotate(-43deg)translate(0);
}

.header__menu-toggle.open .header__menu-toggle__line.three {
  width: 0;
  transition: width .1s .145s, background-color .1s .12s;
}

.social-list__list {
  display: flex;
}

.social-list__item a {
  margin-left: 20px;
}

.social-list__item a:hover svg {
  color: #000;
}

.social-list__item:first-child a {
  margin-left: 0;
}

.social-list__item svg {
  width: 30px;
  color: red;
}

@media (min-width: 1024px) {
  .social-list {
    padding: 20px 0;
  }

  .social-list img {
    width: 40px;
  }
}

.footer {
  width: 100%;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
}

.footer__logo {
  width: 150px;
  margin-bottom: 10px;
}

.footer__link {
  color: red;
  margin-bottom: 10px;
}

.desktop-menu {
  visibility: hidden;
  z-index: 270;
  display: none;
}

@media (min-width: 1024px) {
  .desktop-menu {
    visibility: visible;
    display: block;
  }
}

.desktop-menu__list {
  display: flex;
}

.desktop-menu__item {
  margin-right: 20px;
}

.desktop-menu__item:last-child {
  margin-right: 0;
}

.desktop-menu__link {
  color: red;
  padding: 5px 0;
  font-family: moret, serif;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
}

.desktop-menu__link:hover, .desktop-menu__link.active {
  color: #3c3c3c;
}

.desktop-menu__dot {
  width: 5px;
  height: 5px;
  opacity: 0;
  background-color: #fff;
  border-radius: 50%;
  margin: auto;
  transform: translateY(3px);
}

.main-menu {
  z-index: 240;
  width: 100%;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 120px;
  transition: all .2s .5s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

@media (min-width: 1024px) {
  .main-menu {
    visibility: hidden;
    display: none;
  }
}

.main-menu.open {
  transition: all .2s;
  transform: translateX(0);
}

.main-menu__list {
  min-height: 336px;
  max-width: 300px;
  width: 100%;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  margin: 0 auto;
  display: flex;
}

.main-menu__item {
  text-align: center;
}

.main-menu__link {
  color: red;
  border-bottom: 1px solid #d2d2d2;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  font-family: moret, serif;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.main-menu__link:hover {
  color: #3c3c3c;
}

.hero {
  width: 100%;
  padding-top: 90px;
}

.hero img {
  height: 100%;
  width: 100%;
}

@media (min-width: 1024px) {
  .hero {
    padding-top: 120px;
  }
}

.about {
  padding-top: 40px;
  padding-bottom: 40px;
}

.about__logo {
  margin-bottom: 20px;
}

.about__intro {
  font-size: 2rem;
  line-height: 2rem;
}

.about__icon {
  width: 25px;
  margin: 20px 0;
}

.about h3, .about p {
  margin-bottom: 20px;
}

.band {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.band__image {
  width: 100%;
  display: block;
}

.band__logo {
  width: 300px;
  margin: 20px auto 0;
  display: block;
}

.band .row {
  max-width: 950px;
  width: 100%;
  z-index: 2;
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1024px) {
  .band .row {
    padding-bottom: 20px;
    position: relative;
    top: 10px;
  }
}

.band .col {
  text-align: center;
  flex-direction: column;
  flex: 0 0 25%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.band .col h3 {
  margin-bottom: 15px;
  font-size: 35px;
}

.band .col h5 {
  margin-bottom: 5px;
  font-size: 18px;
}

.band .col h6 {
  font-size: 16px;
}

.band .col br {
  display: none;
}

@media (max-width: 1024px) {
  .band .col h3 {
    margin-bottom: 5px;
    font-size: 27px;
  }

  .band .col h5 {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .band .col h6 {
    font-size: 13px;
  }

  .band .col br {
    display: block;
  }
}

.insta-feed {
  padding-top: 40px;
  padding-bottom: 40px;
}

.insta-feed h2 {
  margin-bottom: 15px;
  font-size: 2rem;
  line-height: 2rem;
}

@media (min-width: 1024px) {
  .insta-feed h2 {
    font-size: 4rem;
    line-height: 4rem;
  }
}

.insta-feed p {
  max-width: 520px;
  margin: 0 auto 15px;
  font-family: moret, serif;
  font-size: 1.2rem;
  line-height: 1.3rem;
}

@media (min-width: 1024px) {
  .insta-feed p {
    font-size: 2rem;
    line-height: 2.1rem;
  }
}

.insta-feed p.follow {
  font-size: 1rem;
}

.insta-feed #curator-feed-default-feed-layout {
  margin-top: 20px;
}

.video {
  max-width: 1400px;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 0 auto;
  padding: 40px 20px;
  display: grid;
}

@media (max-width: 768px) {
  .video {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
}

.video__wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 52.25%;
  position: relative;
}

.video__iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pattern {
  height: 150px;
  width: 100vw;
  background: url("pattern.ce1e2e55.png") no-repeat;
  position: relative;
}

@media (min-width: 1024px) {
  .pattern {
    background-size: cover;
    background-attachment: fixed;
  }
}

/*# sourceMappingURL=index.8d10f427.css.map */
