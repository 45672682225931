.desktop-menu {
	$menu: &;
	display: none;
	visibility: hidden;
	z-index: 270;

	@media (min-width: 1024px) {
		display: block;
		visibility: visible;
	}

	&__list {
		display: flex;
	}

	&__item {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		font-size: 1.2rem;
		color: red;
		font-weight: 600;
		text-decoration: none;
		padding: 5px 0;
		font-family: moret, serif;

		&:hover,
		&.active {
			color: #3c3c3c;
		}
	}

	&__dot {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		transform: translateY(3px);
		opacity: 0;
		margin: auto;
		background-color: #fff;
	}
}
