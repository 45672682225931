.footer {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #fff;
	padding: 20px 0;

	&__logo {
		width: 150px;
		margin-bottom: 10px;
	}

	&__link {
		margin-bottom: 10px;
		color: red;
	}
}
