/* ------------------------------------------------------------ *\
    Reset
\* ------------------------------------------------------------ */

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
    display: block;
}

html,
body {
    min-height: 100%;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

img,
iframe,
video,
audio,
object {
    max-width: 100%;
}

img,
iframe {
    border: 0 none;
}

img {
    height: auto;
    display: inline-block;
    vertical-align: middle;
}

b,
strong {
    font-weight: bold;
}

address {
    font-style: normal;
}

svg:not(:root) {
    overflow: hidden;
}

a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='image'],
label[for] {
    cursor: pointer;
}

a[href^='tel'],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
    cursor: default;
}

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea,
a[href^='tel'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    display: none;
    -webkit-appearance: none;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

textarea {
    overflow: auto;
    resize: none;
    -webkit-overflow-scrolling: touch;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

button,
select {
    text-transform: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

nav ul,
nav ol {
    list-style: none outside none;
}
